

import { gmapApi } from 'vue2-google-maps';

import FETCHING from  '../../library/fetching'
import UMUM from  '../../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        sub_unit_kerja_id  : '',
        m_jenis_barang_id   : '',
        register  : '',
        luas  : '',
        tahun  : '',
        alamat  : '',
        hak  : '',
        tgl  : '',
        no  : '',
        penggunaan  : '',
        m_asal_usul_id   : '',
        harga  : '',
        keterangan  : '',
        createdAt  : '',
        createdBy  : '',
        koordinat: []
        
      },

      url: {
        GET_URL: "https://server-simbada.konaweselatankab.go.id/api/v1/petadasarnew/",
      },
     

      uraian : {
        sub_unit_kerja_uraian : '',
        jenis_barang_uraian : '',
        jenis_barang_kode : '',
        asal_usul_uraian : '',
      },
     
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 8,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,
      mdl_detile: false,

      mdl_lihat : false,

      FETCHING : FETCHING,
      UMUM : UMUM,



      paths: [
            [ {lat:-4.047896, lng: 122.514574}, {lat:-4.044813, lng: 122.518007}, {lat: -4.042502, lng: 122.514917}, {lat: -4.044642, lng: 122.512170} ],

            [ {lat:-4.058654, lng: 122.515030}, {lat:-4.056354, lng: 122.516426}, {lat: -4.055688, lng: 122.514302}, {lat: -4.058533, lng: 122.512056} ]
      ],


      // ==========================================PETA DASAR====================================
      hasil: {
        kecamatan: '',
        des_kel_id: ''
      },
      kecamatan: [],
      desa: [],
      peta: true,
      kiba: true,
      mdl_cek: false,

      petadasar: [],
      kawasanKiba: [],


      clusterOptions: {
        gridSize: 30,
        maxZoom: 15,
      },
      no: 0,
    
      polygonZonaOptions: {
        fillColor: 'red',
        fillOpacity: 0.5,
        strokeOpacity: 1,
        strokeWeight: 1,
        // Opsi warna dan kecerahan arsiran polygon
      },

      polygonHutanOptions: {
        fillColor: 'green',
        fillOpacity: 0.5,
        strokeOpacity: 1,
        strokeWeight: 1,
        // Opsi warna dan kecerahan arsiran polygon
      },

      infoWindowPos: '', // Infowindow position
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWinOpen: false,
      currentMidx: null,
      data_select: {
        id: '',

        nama_desa: '',
        nama_kecamatan: '',
        kode_kecamatan: '',
        kode_desa: '',
        coordinat: [],
      },

      polygonZonaOptionsModal: {
        fillColor: 'blue',
        fillOpacity: 0.3,
        strokeOpacity: 1,
        strokeWeight: 1,
        // Opsi warna dan kecerahan arsiran polygon
      },


    }
  },
  methods: {


    selectData : function(data){
        // this.tunggu('','', data.sub_unit_kerja_id);

        console.log(data);


        this.form.id = data.id;
        this.form.sub_unit_kerja_id  = data.sub_unit_kerja_id;
        this.form.m_jenis_barang_id   = data.m_jenis_barang_id;
        this.form.register  = data.register;
        this.form.luas  = data.luas;
        this.form.tahun  = data.tahun;
        this.form.alamat  = data.alamat;
        this.form.hak  = data.hak;
        this.form.tgl  = data.tgl;
        this.form.no  = data.no;
        this.form.penggunaan  = data.penggunaan;
        this.form.m_asal_usul_id   = data.m_asal_usul_id;
        this.form.harga  = data.harga;
        this.form.keterangan  = data.keterangan;
        this.form.createdAt  = data.createdAt;
        this.form.createdBy  = data.createdBy;
        this.form.koordinat  = data.koordinat;


          this.uraian.sub_unit_kerja_uraian = data.m_sub_unit_kerja_uraian;
          this.uraian.jenis_barang_uraian = data.m_jenis_barang_uraian;
          this.uraian.asal_usul_uraian = data.m_asal_usul_uraian;
          this.uraian.jenis_barang_kode = data.jenis_barang_kode;
       
    },

    filterData : function (val, update, abort) {
            update(async () => {
              console.log(val);
              if (val === '') {}
              else {
                this.$store.state.listDesKel = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_DES_KEL + "autocomplete", {cari : val})
              }
            })
        },

    detileTanah : function(){
      alert('aaaa')
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
       

        cari_data : function(){
            this.page_first = 1;

            this.getDesa();
            this.getPetadasar();
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        tunggu : async function(aa){
          this.$store.state.listDesKel = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_DES_KEL + "autocomplete", {cari : aa})
        },


    // ====================================== PAGINATE ====================================
    
    
    
    
    
    // ====================================== PETA DASAR ====================================
    
    getView: function () {
      this.cek_load_data = true;


      fetch(this.$store.state.url.MAPS_KIBA + "view", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },

      })
        .then(res => res.json())
        .then(res_data => {

          if (this.kiba) {
            this.kawasanKiba = res_data;

          } else {
            this.kawasanKiba = [];
          }
         


        });
    },

    getPetadasar: function (id_kecamatan) {
      this.cek_load_data = true;


      var kecamatan_id = '';

      if (this.hasil.kecamatan.id == null || this.hasil.kecamatan.id == undefined || this.hasil.kecamatan.id == '') {
        kecamatan_id = id_kecamatan;
      } else {
        kecamatan_id = this.hasil.kecamatan.id;
      }

      var des_kel_id = this.hasil.des_kel_id.id;

      fetch(this.url.GET_URL + "petadasar", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          kecamatan_id: kecamatan_id,
          des_kel_id: des_kel_id,
        })
      })
        .then(res => res.json())
        .then(res_data => {

          if (this.peta) {
            this.petadasar = res_data;
          } else {
            this.petadasar = [];
          }
          // this.petadasar = res_data;
          // this.cek_load_data = false;

          // 


        });
    },

    getDesa: function () {
      this.cek_load_data = true;

      fetch(this.url.GET_URL + "desa", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          kecamatan_id: this.hasil.kecamatan.id,
        })

      })
        .then(res => res.json())
        .then(res_data => {


          var tampung = [];

          var no_kec = '';


          for (var i = 0; i < res_data.length; i++) {

            if (res_data[i].no_kec < 10) {
              no_kec = `0${res_data[i].no_kec}`;
            }
            else {
              no_kec = `${res_data[i].no_kec}`
            }


            tampung.push({
              // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
              des_kel_id: `${res_data[i].no_prop}.0${res_data[i].no_kab}.${no_kec}.${res_data[i].kode}`,
              nama_des_kel: res_data[i].uraian
            })
          }

          // Tambah Ranowila
          if (this.hasil.kecamatan.id == '74.05.18') {
            tampung.push({
              // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
              des_kel_id: '74.05.18.2006',
              nama_des_kel: 'RANOWILA'
            })
          }
          if (this.hasil.kecamatan.id == '74.05.07') {

            // Remove Ranowila
            tampung.splice(16, 1);

          }



          this.desa = tampung;

          this.cek_load_data = false;

        });
    },

    getKecamatan: function () {
      this.cek_load_data = true;

      console.log("isdjfiuhsfuihifd");

      fetch(this.url.GET_URL + "kecamatan", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          kecamatan_id: this.id_kecamatan

        })
      })
        .then(res => res.json())
        .then(res_data => {

          var tampung = [];

          var kode = '';

          for (var i = 0; i < res_data.length; i++) {

            if (res_data[i].hasil.kode < 10) {
              kode = `0${res_data[i].hasil.kode}`;
            }
            else {
              kode = `${res_data[i].hasil.kode}`
            }


            tampung.push({
              // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.kode}`, 
              kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.${kode}`,
              nama_kecamatan: res_data[i].hasil.uraian
            })
          }


          this.kecamatan = tampung;


          this.cek_load_data = false;
        });
    },

    calculatePolygonCentroid(data) {


      const totalLat = data.reduce((sum, point) => sum + point.lat, 0);
      const totalLng = data.reduce((sum, point) => sum + point.lng, 0);
      const avgLat = totalLat / data.length;
      const avgLng = totalLng / data.length;

      var hasil = {
        lat: avgLat,
        lng: avgLng,
      };


return hasil;
    },

    handlePolygonClick: function(data){

    this.Notify_marker(`Zona ini adalah Desa ${data.nama_desa}, Kecamatan ${data.nama_kecamatan} `);

    },
    
    Notify_marker : function(message){
          this.$q.notify({
            progress: true,
            message: message,
            color: 'success',
            avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
            position : 'top',
            actions: [
              { label: 'Keluar', color: 'yellow', handler: () => { /* ... */ } }
            ],
            timeout: 10000,
          })
    },

    selectDataPetaDasar: function (data) {


      this.data_select = {
        id: data.id,
        nama_desa: data.nama_desa,
        nama_kecamatan: data.nama_kecamatan,
        kode_kecamatan: data.kode_kecamatan,
        kode_desa: data.kode_desa,
        coordinat: data.coordinat,

      };



      },

      onMarkerClick(marker, idx) {
      // Handle marker click event
      // console.log(marker);
      this.infoWindowPos = { lat: marker.lat, lng: marker.lng };
      this.infoOptions.content = `latitude : ${marker.lat}, longitude: ${marker.lng}`;;
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    },



    // ====================================== END PETA DASAR ====================================
  },


  mounted () {
    this.getView();
    this.getKecamatan();
    this.getPetadasar();

  },

  computed: {
    google: gmapApi

  },
}
